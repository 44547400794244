import React from "react"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  min-width: 200px;

  flex-basis: 20%;

  @media (max-width: 1000px) {
    /* flex-basis: 30%; */
  }
`
const Icon = styled.img`
  margin: 0;
  height: 60px;
  object-fit: contain;
  align-self: flex-start;
  margin-bottom: 25px;
`
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
`
const Title = styled.h3`
  font-family: "alternategothic2_btregular";
  font-size: 15px;
  margin-bottom: 5px;
  text-transform: uppercase;
`
const Copy = styled.p`
  margin: 0;
  font-size: 15px;
  font-weight: 100;
`
const List = styled.ul`
  margin: 0;
  list-style: none;
`
const Item = styled.li`
  margin: 0;
  font-size: 14px;
  font-weight: 100;
  font-family: "Open Sans";
`

const ServiceItem = props => (
  <Container>
    <Icon src={props.icon} />
    <InfoContainer>
      <Title>{props.title}</Title>
      <List>
        {props.services.map((service, index) => (
          <Item key={index}>{service}</Item>
        ))}
      </List>
    </InfoContainer>
  </Container>
)

export default ServiceItem
