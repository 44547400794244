import React, { Component } from "react"
import styled from "styled-components"
import { IoIosClose } from "react-icons/io"

class HireForm extends Component {
  state = {}
  render() {
    return (
      <Container>
        <CloseContainer>
          <IoIosClose />
        </CloseContainer>
        <Title>We'd love to work with you</Title>
        <FormContainer>
          <Group>
            <Input placeholder="First Name" />
            <Input placeholder="Last Name" />
          </Group>
          <Input placeholder="Email Address" />
          <TextArea placeholder="Your question here" rows="4" />
          <SubmitBtn>Submit</SubmitBtn>
        </FormContainer>
      </Container>
    )
  }
}

export default HireForm

const Container = styled.div`
  background: white;
  padding: 20px;
`
const CloseContainer = styled.div`
  width: 100%;
  justify-content: flex-end;
  display: flex;
`
const Title = styled.h1`
  font-family: "Open Sans";
  font-size: 13px;
  margin-bottom: 15px;
  margin-top: 15px;
`
const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`
const Group = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`
const Input = styled.input`
  border: 1px solid gray;
  padding: 10px;
  margin: 0 0 15px 0;
`
const TextArea = styled.textarea`
  border: 1px solid gray;
  padding: 10px;
  margin: 0 0 15px 0;
`
const SubmitBtn = styled.button`
  color: white;
  background: black;
  width: 150px;
  border: none;
`
