import React, { Component } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import PageHeader from "../components/pageHeader"
import ServiceItem from "../components/service-item"
import HireForm from "../components/hire-form"
import SEO from "../components/seo"
import { graphql } from "gatsby"

const Cover = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100vh;
  width: 100%;
  object-fit: cover;
`
const ServiceGrid = styled.div`
  margin: 0 auto;
  margin-top: 30vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* grid-template-columns: 300px 300px 300px 300px; */
  max-width: 1000px;
  justify-content: center;
  align-items: flex-start;
`
const ContactForm = styled.div`
  position: fixed;
  top: 50%;
  left: 20%;
`

class ServicesPage extends Component {
  state = {}
  render() {
    const pageData = this.props.data.allWordpressPage.edges[0].node
    return (
      <Layout>
        <SEO title="Services" />
        <PageHeader>Services</PageHeader>
        <Cover src={pageData.acf.cover.source_url} />
        <ServiceGrid>
          <ServiceItem
            title="COMMERCIALS"
            services={commercials}
            icon={require("../images/icons/TTRocks_commercials-01.png")}
          />
          <ServiceItem
            title="Filmmaking"
            services={filmaking}
            icon={require("../images/icons/TTRocks_films.png")}
          />
          <ServiceItem
            title="Photography"
            services={photography}
            icon={require("../images/icons/TTRocks_photographs.png")}
          />
          <ServiceItem
            title="LOCAL FIXERS/CO-PRODUCTIONS"
            services={photography}
            icon={require("../images/icons/TTRocks_T_T.png")}
          />
        </ServiceGrid>
        {/* <ContactForm>
          <HireForm />
        </ContactForm> */}
      </Layout>
    )
  }
}

export default ServicesPage

const commercials = [
  "Television Ads",
  "Documentary Filmmaking",
  "Promotional Videos",
  "Public Service Announcements",
  "Corporate Video & Event Capture",
  "Full Service (Pre-Production, Production & Post-Production)",
  "Scripting & Storyboard",
  "Editing, Colour Correction, Graphics",
  "Original Music Scoring",
  "Sound Design, Sound Editing, Audio Mixing",
  "Stock Footage",
]
const filmaking = [
  "Scripting",
  "Pre-Production",
  "Directing",
  "Producing",
  "Cinematography",
  "Editing",
  "Sound Mixing",
  "Colour Correcting",
  "Graphics",
]
const photography = [
  "Commercial Photography",
  "Billboards",
  "Behind the Scenes",
  "Artists & Public Figures (Album covers, websites, calendars)",
  "Beauty & Fashion Photography",
  "Documentary Photography",
  "Event Photography",
  "Stock Photography",
]
const fixers = [
  "Location Scouting",
  "Casting",
  "Storyboard & Treatments",
  "Script Development",
  "Stock Footage",
]
export const servicesQuery = graphql`
  query($homepage: String = "Services") {
    allWordpressPage(filter: { title: { eq: $homepage } }) {
      edges {
        node {
          title
          acf {
            cover {
              source_url
              alt_text
            }
          }
        }
      }
    }
  }
`
